import React from 'react';
import { Text } from '@contentful/rich-text-types';
import { MarketingCardFields } from '@ts/index';
import ComponentHeader from '../ComponentHeader';
import InteractiveCard from '../InteractiveCard';
import styles from './BlockGrid.module.scss';

type BlockGridProps = {
	title?: string;
	subtitle?: string;
	blocks?: Array<MarketingCardFields>;
};

const BlockGrid = ({ title, subtitle, blocks }: BlockGridProps) => {
	return (
		<div className={styles.container}>
			{(title || subtitle) && <ComponentHeader title={title} subtitle={subtitle} />}
			{blocks && (
				<div className={styles.grid}>
					{blocks.map(block => (
						<InteractiveCard
							key={block.slug}
							background={block.background.url}
							title={block.title}
							text={!!block.text ? (block.text?.content[0].content[0] as Text).value : null}
							subtitle={block.subtitle}
							subheading={
								!block.shouldExpand && !!block.text ? (block.text?.content[0].content[0] as Text).value : ''
							}
							shouldExpand={block.shouldExpand}
							button1={{
								label: block.buttonLabel1,
								link: block.buttonLink1,
								color: block.buttonColor1,
							}}
							{...(block.buttonLabel2 && {
								button2: {
									label: block.buttonLabel2,
									link: block.buttonLink2,
									color: block.buttonColor2,
								},
							})}
						/>
					))}
				</div>
			)}
		</div>
	);
};

export default BlockGrid;
