import { useMemo } from 'react';
import { Flex, Grid, Heading, InteractiveCard, Paragraph } from '@components';
import { useContentfulAsset } from '@services/contentful/hooks';
import { InteractiveCardFields } from '@ts/contentful';
import { LOGGED_IN_MARKETING_PROPOSITIONS, LOGGED_OUT_MARKETING_PROPOSITIONS } from '@utils/constants';
import { useCustomer } from '@services/shopify';
import styles from './LoggedInMarketingGrid.module.scss';

const LoggedInMarketingGrid = () => {
	// const { data: customer } = useCustomer()
	//
	// const CMS_ID: Array<{ id: string, cmsSlug: string }> = customer ? LOGGED_IN_MARKETING_PROPOSITIONS : LOGGED_OUT_MARKETING_PROPOSITIONS
	//
	// const { data } = useContentfulAsset<InteractiveCardFields>({
	// 	id: CMS_ID.map(val => val.id).join('+'),
	// 	query: {
	// 		'content_type': 'plpMarketingBlock',
	// 		'fields.slug[in]': CMS_ID.map(val => val.cmsSlug).join(','),
	// 	},
	// });
	//
	// // Data comes from CMS unsorted, so we enforce the order defined in `CMS_ID` here
	// const sortedCards = useMemo(() => !data ? null : data.sort((a, b) => {
	// 	const aIndex = CMS_ID.findIndex(v => v.cmsSlug === a.fields.slug);
	// 	const bIndex = CMS_ID.findIndex(v => v.cmsSlug === b.fields.slug);
	// 	return aIndex - bIndex;
	// }), [CMS_ID, data]);
	//
	// if(!sortedCards) return null;
	//
	// return (
	// 	<div className={styles.section} data-marketing-grid>
	// 		<div className={styles.container}>
	// 			<Flex column align='center'>
	// 				<Heading tag='h3'>Grow Your Collection</Heading>
	// 				<Paragraph className={styles.subtitle}>From Rx sunglasses and accessories to luxe Top Frames, we’ve got everything you need to take your look to the next level.</Paragraph>
	// 			</Flex>
	// 			<Grid columns={2} gap={3} style={{ paddingTop: '3.2rem' }}>
	// 				{sortedCards.map(({ fields }, index) => {
	// 					const { title, background, buttonLabel1, buttonLink1, buttonLabel2, buttonLink2, buttonColor1, buttonColor2 } = fields;
	// 					return (
	// 						<InteractiveCard
	// 							key={index}
	// 							background={background.fields.file.url}
	// 							title={title}
	// 							button1={{
	// 								label: buttonLabel1,
	// 								link: buttonLink1,
	// 								color: buttonColor1,
	// 							}}
	// 							button2={buttonLabel2 ? {
	// 								label: buttonLabel2,
	// 								link: buttonLink2,
	// 								color: buttonColor2,
	// 							} : null}
	// 							className={styles.valueProp}
	// 							shouldExpand={false}
	// 							asValueProp
	// 						/>
	// 					);
	// 				})}
	// 			</Grid>
	// 		</div>
	// 	</div>
	// );
	return null;
};

export default LoggedInMarketingGrid;
