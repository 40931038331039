import Parser from 'html-react-parser';
import { Container, Flex, Heading, Paragraph } from '@components';
import { getArticleDate } from '@utils/blog';
import styles from './ArticlePage.module.scss';

const removeNonBreakingSpaces = htmlContent => {
	return htmlContent.replace(/&nbsp;|&#160;|\u00A0/g, '');
};

const ArticlePage = ({ article }) => {
	const sanitizedHtml = removeNonBreakingSpaces(article.contentHtml);

	return (
		<Flex column align={'center'} key={article.title} className={styles.container}>
			<Container className={styles.article}>
				<Heading tag='h1'>{article.title}</Heading>
				<Paragraph className={styles.date}>{getArticleDate(article.publishedAt)}</Paragraph>
				<Container className={styles.content}>{Parser(sanitizedHtml)}</Container>
			</Container>
		</Flex>
	);
};

export default ArticlePage;
