/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import cn from 'classnames';
import { m } from 'framer-motion';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { useRouter } from 'next/router';
import variables from '@styles/export.module.scss';
import {
	Chevron,
	Flex,
	Lozenge,
	NonRx,
	Paragraph,
	Progressive,
	Readers,
	Select,
	SingleVision,
	Title,
	TypographyButton,
} from '@components';
import { formatCurrency } from '@utils/shopify';
import { useBFContext } from '@context';
import {
	BUILD_FLOW_STEPS,
	BUILD_FLOW_STEP_DIRECTION,
	CUSTOMER_JOURNEYS,
	LOCALE_CODES,
	LOCALE_DICT,
	READERS_STRENGTH_COPY,
	READER_STRENGTHS,
} from '@constants';
import { LensOption } from '@ts/index';
import { buildFlowPrescriptionVariants } from '@utils/motions';
import { RX_TYPE } from '@utils/constants/base-skus';
import { getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import styles from '../PrescriptionStep/PrescriptionStep.module.scss';

const PrescriptionIcons = {
	'single-vision': ({ className }) => <SingleVision className={className} />,
	'non-prescription': ({ className }) => <NonRx className={className} />,
	'readers': ({ className }) => <Readers className={className} />,
	'progressives': ({ className }) => <Progressive className={className} />,
};

const Prescriptions = () => {
	const {
		setStepChange,
		setRxType,
		setReaderRx,
		readerRx,
		rxType,
		step,
		journey,
		lensOptions,
		variantPrices,
		BaseFrameVariant,
	} = useBFContext();
	const { locale } = useRouter();
	const isLensesStep = step === BUILD_FLOW_STEPS.CUSTOMIZE;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isNotEyeGlasses = journey !== CUSTOMER_JOURNEYS.EYEGLASSES;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	const updateReaderStrength = useCallback(strength => {
		const cleanStr: (typeof READER_STRENGTHS)[number] = strength.replace('+', '');
		setReaderRx(cleanStr);
	}, []);

	const handleClick = useCallback(
		(option: LensOption) => {
			if (isNotEyeGlasses && option.optionValue !== RX_TYPE.READERS) {
				setRxType(option.optionValue);
			} else {
				setRxType(option.optionValue);
				setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
			}
		},
		[isLensesStep, isReadersSelected]
	);

	const mappedPrescriptions = lensOptions.map((option: LensOption, index: number) => {
		const isOptionSelected = rxType === option.optionValue;
		const showReaderStrength = isReadersSelected && isOptionSelected && isLensesStep;
		const variantPriceDiff = getVariantPriceDiff({
			lookup: variantPrices,
			current: BaseFrameVariant,
			rxType: option.optionValue,
			sunLensException: isSunLens,
			locale,
			currencyCode: LOCALE_DICT[locale].currencyCode,
		});
		const additionalPrice = isOptionSelected ? variantPriceDiff.without : variantPriceDiff.with;

		const priceCopy = isOptionSelected ? 'Selected' : `+${formatCurrency(additionalPrice, showCurr)}`;
		const classes = cn(styles['rx-list-item'], {
			[styles['hover-disabled']]: isLensesStep,
			[styles['is-readers']]: isReadersSelected,
			[styles['extra-z-index']]: isReadersSelected,
		});

		let animate = 'initial';
		let initial = 'initial';
		if (isLensesStep) {
			animate = isOptionSelected && (isReadersSelected || !isSunLens) ? 'active' : 'inactive';
			initial = isOptionSelected ? 'active' : 'inactive';
		}

		return (
			<m.div
				key={option.id}
				id={option.id}
				className={classes}
				initial={initial}
				data-prescription-type={option.copy}
				animate={animate}
				variants={buildFlowPrescriptionVariants(index)}
				transition={{ type: 'spring', stiffness: 256, damping: 24, duration: 0.25, mass: 1 }} // MODIFIED
				custom={index}
				onClick={() => handleClick(option)}
			>
				<Flex fullHeight fullWidth>
					{PrescriptionIcons[option.id]({ className: styles.rxIcon })}
					<Flex
						align='center'
						gap={2}
						fullWidth
						style={{ flexGrow: 1, backgroundColor: 'transparent', padding: '1.2rem' }}
					>
						<Flex column gap={1} align='start' style={{ flexGrow: 1 }}>
							{!isLensesStep && (
								<Flex align='center' gap={2} position={'relative'} fullWidth>
									{option.optionValue === RX_TYPE.PROGRESSIVE && (
										<Lozenge
											text='Our Specialty'
											shape='square'
											color={variables.green2}
											backgroundColor={variables.greenLight}
										/>
									)}
									<div style={{ flexGrow: 1 }} />
									<Paragraph
										className={styles['price']}
										style={{ color: isOptionSelected ? variables.green2 : '' }}
									>
										{priceCopy}
									</Paragraph>
								</Flex>
							)}
							<Title>{option.copy}</Title>
							{!isLensesStep && <Paragraph style={{ color: variables.gray4 }}>{option.description}</Paragraph>}
							{isLensesStep && (
								<Paragraph
									className={styles.selectedText}
									style={{ color: isOptionSelected ? variables.green2 : '' }}
								>
									{priceCopy}
								</Paragraph>
							)}
						</Flex>
						{!isLensesStep && (
							<div className={styles.chevronContainer}>
								<Chevron direction='right' color={variables.blue2} />
							</div>
						)}
					</Flex>
				</Flex>
				{showReaderStrength && (
					<div className={styles['readers']} onClick={e => e.stopPropagation()}>
						<Flex justify='between' align='center'>
							<TypographyButton small>{READERS_STRENGTH_COPY}</TypographyButton>
							<Select
								buttonProps={{ size: 'small' }}
								values={READER_STRENGTHS.map(rs => `+${rs}`)}
								handler={updateReaderStrength}
								placeholder={readerRx ? `+${readerRx}` : 'Select'}
							/>
						</Flex>
					</div>
				)}
			</m.div>
		);
	});

	return isLensesStep && !isReadersSelected ? null : (
		<RadioGroupPrimitive.Root asChild value={rxType}>
			<>{mappedPrescriptions}</>
		</RadioGroupPrimitive.Root>
	);
};

export default Prescriptions;
