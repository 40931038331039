import { useMemo, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import cn from 'classnames';
import { NormalizedProduct } from '@ts/product';
import { useIsMobile } from '@utils/hooks';
import {
	Button,
	Chevron,
	Clock,
	ComponentHeader,
	Flex,
	Heading,
	HeartSparkle,
	Img,
	Loading,
	Lozenge,
	Spacer,
	Sparkle,
	VerticalCard,
} from '@components';
import { PRODUCT_TYPES } from '@constants';
import styles from './CollectionSlider.module.scss';

type CollectionSliderProps = {
	iconType: 'heart' | 'star' | 'clock';
	header: string;
	subHeader: string;
	featuredCollection?: {
		backgroundUrl: string;
		title: string;
		link: string;
		lozenge?: {
			text: string;
			backgroundColor: string;
			color: string;
		};
	};
	products: NormalizedProduct[];
	buttonData?: {
		text: string;
		link: string;
	};
	limit?: number;
	isDataLoading?: boolean;
	sliderType: 'favorites' | 'recommendations' | 'leaving-soon';
};

const CollectionSlider = ({
	iconType,
	header,
	subHeader,
	featuredCollection,
	products,
	buttonData,
	limit = products.length,
	isDataLoading = true,
	sliderType,
}: CollectionSliderProps) => {
	const isMobile = useIsMobile();
	const limitedProducts = useMemo(() => (products ? products.slice(0, limit) : []), [products, limit]);

	const [isBeginning, setIsBeginning] = useState(false);
	const [isEnd, setIsEnd] = useState(false);

	const navigationPrevRef = useRef(null);
	const navigationNextRef = useRef(null);
	const navigationRefs = {
		prevEl: navigationPrevRef.current,
		nextEl: navigationNextRef.current,
	};

	const getIcon = (iconType: string) => {
		switch (iconType) {
			case 'heart':
				return <HeartSparkle />;
			case 'clock':
				return <Clock />;
			case 'star':
				return <Sparkle />;
			default:
				return null;
		}
	};

	return (
		<div className={styles.container} data-collection-slider={sliderType}>
			<ComponentHeader title={header} subtitle={subHeader} headerArt={getIcon(iconType)} withDivider={!isMobile}>
				{buttonData && <Button size={'small'} color={'white'} label={buttonData.text} href={buttonData.link} />}
			</ComponentHeader>

			<Spacer size='2.4rem' />
			{isDataLoading ? (
				<Loading />
			) : (
				<Swiper
					direction='horizontal'
					spaceBetween={8}
					slidesPerView={1.2}
					modules={[Navigation]}
					resistance={true}
					width={isMobile ? 300 : 400}
					style={{ overflow: 'visible' }}
					navigation={navigationRefs}
					onSlideChange={swiper => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
					}}
					onSlidesGridLengthChange={swiper => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
						swiper.on('reachEnd', () => {
							setIsEnd(true);
						});
					}}
				>
					{featuredCollection && (
						<SwiperSlide key={`swiper-slide-featured-collection`} className={styles.slideFeatured}>
							<Flex className={styles.featuredCollection}>
								<Img
									className={styles.featuredImage}
									src={featuredCollection.backgroundUrl}
									alt={`${featuredCollection.title} collection`}
								/>
								<div className={styles.featuredContent}>
									{featuredCollection?.lozenge && (
										<Lozenge
											shape={'square'}
											backgroundColor={featuredCollection.lozenge?.backgroundColor}
											color={featuredCollection.lozenge?.color}
										>
											{featuredCollection.lozenge?.text}
										</Lozenge>
									)}
									<Spacer size='0.8rem' />
									<Heading tag='h4' className={styles.featuredContentTitle}>
										{featuredCollection.title}
									</Heading>
									<Spacer size='0.8rem' />
									<Button
										size={'small'}
										color={'transparent-light'}
										label={'Explore Collection'}
										href={featuredCollection.link}
									/>
								</div>
							</Flex>
						</SwiperSlide>
					)}
					{limitedProducts.map((product, index) => {
						return (
							<SwiperSlide key={`swiper-slide-${product.id}-${index}`} className={styles.slide}>
								<VerticalCard
									key={`${product.id}-${index}`}
									product={product}
									variant={product.variants[0]}
									secondaryAction='favorite'
									aspectRatio={'4/3'}
									showCollectionLozenge={true}
									primaryAction={product.type.includes(PRODUCT_TYPES.BASE_FRAME) ? 'view' : 'cart'}
								/>
							</SwiperSlide>
						);
					})}
					<div className={styles.productCarousel}>
						<div className={cn(styles['prevButton'], { [styles['disable']]: isBeginning })} ref={navigationPrevRef}>
							<Chevron extraClasses={styles.chevron} direction='left' />
						</div>

						<div className={cn(styles['nextButton'], { [styles['disable']]: isEnd })} ref={navigationNextRef}>
							<Chevron extraClasses={styles.chevron} direction='right' />
						</div>
					</div>
				</Swiper>
			)}
		</div>
	);
};

export default CollectionSlider;
