const BlueLight = ({ className }) => {
	return (
		<svg viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
			<path
				d='M12.9013 20.8276C12.4035 18.9698 13.506 17.0603 15.3637 16.5626L43.9551 8.90152C45.8128 8.40375 47.7223 9.5062 48.2201 11.3639L65.5701 76.115C66.0679 77.9728 64.9654 79.8823 63.1077 80.38L34.5163 88.0411C32.6586 88.5389 30.7491 87.4364 30.2513 85.5787L12.9013 20.8276Z'
				fill='#FF510E'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M61.6935 82.8295C64.6181 82.0458 66.3537 79.0396 65.5701 76.115C66.0678 77.9727 64.9654 79.8822 63.1077 80.38L34.5163 88.041C32.6586 88.5388 30.7491 87.4363 30.2513 85.5786L12.9013 20.8275C12.4035 18.9698 13.5059 17.0603 15.3637 16.5625C12.439 17.3462 10.7034 20.3523 11.4871 23.277L28.3194 86.0963C29.1031 89.0209 32.1093 90.7565 35.0339 89.9729L61.6935 82.8295Z'
				fill='black'
			/>
			<g clip-path='url(#clip0_15054_5493)'>
				<g clip-path='url(#clip1_15054_5493)'>
					<path
						d='M15.6494 20.9924C15.4005 20.0635 15.9517 19.1088 16.8806 18.8599L43.7901 11.6495C44.719 11.4006 45.6737 11.9518 45.9226 12.8807L62.822 75.95C63.0709 76.8788 62.5197 77.8336 61.5908 78.0825L34.6812 85.2929C33.7524 85.5418 32.7976 84.9905 32.5487 84.0617L15.6494 20.9924Z'
						fill='white'
					/>
					<path
						d='M18.9084 26.4283C18.784 25.9639 19.0596 25.4865 19.524 25.3621L27.0923 23.3342C27.5568 23.2097 28.0341 23.4853 28.1586 23.9498L28.6092 25.6316C28.7337 26.096 28.4581 26.5734 27.9936 26.6978L20.4253 28.7258C19.9609 28.8502 19.4835 28.5746 19.3591 28.1102L18.9084 26.4283Z'
						fill='#EFEDEE'
					/>
					<path
						d='M28.2353 41.0538C28.1109 40.5894 28.3865 40.112 28.8509 39.9876L37.2602 37.7343C37.7246 37.6099 38.202 37.8855 38.3264 38.3499C38.4509 38.8143 38.1752 39.2917 37.7108 39.4162L29.3016 41.6694C28.8372 41.7939 28.3598 41.5182 28.2353 41.0538Z'
						fill='#EFEDEE'
					/>
					<path
						d='M37.9243 77.2135C37.7999 76.7491 38.0755 76.2717 38.5399 76.1472L46.9491 73.894C47.4136 73.7695 47.8909 74.0452 48.0154 74.5096C48.1398 74.974 47.8642 75.4514 47.3998 75.5758L38.9905 77.8291C38.5261 77.9535 38.0487 77.6779 37.9243 77.2135Z'
						fill='#EFEDEE'
					/>
					<path
						d='M22.9643 41.565C22.8399 41.1006 23.1155 40.6232 23.5799 40.4988L25.2618 40.0481C25.7262 39.9237 26.2036 40.1993 26.328 40.6637L26.7787 42.3456C26.9031 42.81 26.6275 43.2874 26.1631 43.4118L24.4812 43.8625C24.0168 43.9869 23.5394 43.7113 23.415 43.2469L22.9643 41.565Z'
						fill='#EFEDEE'
					/>
					<path
						d='M32.6533 77.7247C32.5289 77.2603 32.8045 76.7829 33.2689 76.6585L34.9507 76.2078C35.4152 76.0834 35.8926 76.359 36.017 76.8234L36.4676 78.5053C36.5921 78.9697 36.3165 79.4471 35.852 79.5715L34.1702 80.0222C33.7058 80.1466 33.2284 79.871 33.104 79.4066L32.6533 77.7247Z'
						fill='#EFEDEE'
					/>
					<path
						d='M22.4092 46.2203C22.2847 45.7559 22.5603 45.2785 23.0248 45.1541L51.6162 37.493C52.0806 37.3686 52.558 37.6442 52.6824 38.1086L60.3435 66.7C60.4679 67.1645 60.1923 67.6418 59.7279 67.7663L31.1365 75.4273C30.672 75.5518 30.1946 75.2762 30.0702 74.8117L22.4092 46.2203Z'
						fill='#EFEDEE'
					/>
					<path
						d='M32.3235 83.2208C32.199 82.7564 32.4746 82.279 32.9391 82.1546L61.5305 74.4935C61.9949 74.3691 62.4723 74.6447 62.5967 75.1091L70.2578 103.701C70.3822 104.165 70.1066 104.642 69.6422 104.767L41.0508 112.428C40.5863 112.552 40.109 112.277 39.9845 111.812L32.3235 83.2208Z'
						fill='#EFEDEE'
					/>
					<path
						d='M38.2497 21.2457C38.1253 20.7813 38.4009 20.3039 38.8653 20.1795L40.5472 19.7288C41.0116 19.6044 41.489 19.88 41.6134 20.3444L42.0641 22.0263C42.1885 22.4907 41.9129 22.9681 41.4485 23.0925L39.7666 23.5432C39.3022 23.6676 38.8248 23.392 38.7004 22.9276L38.2497 21.2457Z'
						fill='#EFEDEE'
					/>
					<path
						d='M42.4543 20.1192C42.3299 19.6548 42.6055 19.1774 43.0699 19.053L44.7518 18.6023C45.2162 18.4779 45.6936 18.7535 45.818 19.2179L46.2687 20.8998C46.3931 21.3642 46.1175 21.8416 45.6531 21.966L43.9712 22.4167C43.5068 22.5411 43.0294 22.2655 42.905 21.8011L42.4543 20.1192Z'
						fill='#EFEDEE'
					/>
					<path
						d='M25.982 32.6445C26.3553 34.0377 25.5285 35.4699 24.1352 35.8432C22.7419 36.2165 21.3098 35.3897 20.9365 33.9964C20.5631 32.6031 21.39 31.171 22.7833 30.7977C24.1766 30.4243 25.6087 31.2512 25.982 32.6445Z'
						fill='#EFEDEE'
					/>
					<path
						d='M32.7093 30.8422C33.0826 32.2355 32.2558 33.6676 30.8625 34.041C29.4692 34.4143 28.0371 33.5874 27.6638 32.1942C27.2904 30.8009 28.1173 29.3687 29.5106 28.9954C30.9039 28.6221 32.336 29.4489 32.7093 30.8422Z'
						fill='#EFEDEE'
					/>
					<path
						d='M39.4368 29.0395C39.8102 30.4328 38.9833 31.8649 37.5901 32.2382C36.1968 32.6116 34.7646 31.7847 34.3913 30.3914C34.018 28.9981 34.8448 27.566 36.2381 27.1927C37.6314 26.8193 39.0635 27.6462 39.4368 29.0395Z'
						fill='#EFEDEE'
					/>
					<path
						d='M46.1641 27.2367C46.5375 28.63 45.7106 30.0622 44.3173 30.4355C42.9241 30.8088 41.4919 29.982 41.1186 28.5887C40.7453 27.1954 41.5721 25.7633 42.9654 25.3899C44.3587 25.0166 45.7908 25.8435 46.1641 27.2367Z'
						fill='#EFEDEE'
					/>
					<path
						d='M52.8914 25.434C53.2648 26.8273 52.4379 28.2594 51.0446 28.6328C49.6514 29.0061 48.2192 28.1792 47.8459 26.786C47.4726 25.3927 48.2994 23.9605 49.6927 23.5872C51.086 23.2139 52.5181 24.0407 52.8914 25.434Z'
						fill='#EFEDEE'
					/>
				</g>
			</g>
			<path
				d='M27.0319 19.7453C26.7831 18.8165 27.3343 17.8617 28.2631 17.6128L33.3087 16.2609C34.2375 16.012 35.1923 16.5632 35.4412 17.4921C35.6901 18.4209 35.1388 19.3757 34.21 19.6246L29.1644 20.9765C28.2356 21.2254 27.2808 20.6742 27.0319 19.7453Z'
				fill='#FF510E'
			/>
			<g clip-path='url(#clip2_15054_5493)'>
				<g clip-path='url(#clip3_15054_5493)'>
					<path
						d='M15.6494 20.9924C15.4005 20.0635 15.9517 19.1088 16.8806 18.8599L43.7901 11.6495C44.719 11.4006 45.6737 11.9518 45.9226 12.8807L62.822 75.95C63.0709 76.8788 62.5197 77.8336 61.5908 78.0825L34.6812 85.2929C33.7524 85.5418 32.7976 84.9905 32.5487 84.0617L15.6494 20.9924Z'
						fill='white'
					/>
					<path
						d='M18.9084 26.4283C18.784 25.9639 19.0596 25.4865 19.524 25.3621L27.0923 23.3342C27.5568 23.2097 28.0341 23.4853 28.1586 23.9498L28.6092 25.6316C28.7337 26.096 28.4581 26.5734 27.9936 26.6978L20.4253 28.7258C19.9609 28.8502 19.4835 28.5746 19.3591 28.1102L18.9084 26.4283Z'
						fill='#EFEDEE'
					/>
					<path
						d='M28.2353 41.0538C28.1109 40.5894 28.3865 40.112 28.8509 39.9876L37.2602 37.7343C37.7246 37.6099 38.202 37.8855 38.3264 38.3499C38.4509 38.8143 38.1752 39.2917 37.7108 39.4162L29.3016 41.6694C28.8372 41.7939 28.3598 41.5182 28.2353 41.0538Z'
						fill='#EFEDEE'
					/>
					<path
						d='M37.9243 77.2135C37.7999 76.7491 38.0755 76.2717 38.5399 76.1472L46.9491 73.894C47.4136 73.7695 47.8909 74.0452 48.0154 74.5096C48.1398 74.974 47.8642 75.4514 47.3998 75.5758L38.9905 77.8291C38.5261 77.9535 38.0487 77.6779 37.9243 77.2135Z'
						fill='#EFEDEE'
					/>
					<path
						d='M22.9643 41.565C22.8399 41.1006 23.1155 40.6232 23.5799 40.4988L25.2618 40.0481C25.7262 39.9237 26.2036 40.1993 26.328 40.6637L26.7787 42.3456C26.9031 42.81 26.6275 43.2874 26.1631 43.4118L24.4812 43.8625C24.0168 43.9869 23.5394 43.7113 23.415 43.2469L22.9643 41.565Z'
						fill='#EFEDEE'
					/>
					<path
						d='M32.6533 77.7247C32.5289 77.2603 32.8045 76.7829 33.2689 76.6585L34.9507 76.2078C35.4152 76.0834 35.8926 76.359 36.017 76.8234L36.4676 78.5053C36.5921 78.9697 36.3165 79.4471 35.852 79.5715L34.1702 80.0222C33.7058 80.1466 33.2284 79.871 33.104 79.4066L32.6533 77.7247Z'
						fill='#EFEDEE'
					/>
					<path
						d='M22.4092 46.2203C22.2847 45.7559 22.5603 45.2785 23.0248 45.1541L51.6162 37.493C52.0806 37.3686 52.558 37.6442 52.6824 38.1086L60.3435 66.7C60.4679 67.1645 60.1923 67.6418 59.7279 67.7663L31.1365 75.4273C30.672 75.5518 30.1946 75.2762 30.0702 74.8117L22.4092 46.2203Z'
						fill='#EFEDEE'
					/>
					<path
						d='M32.3235 83.2208C32.199 82.7564 32.4746 82.279 32.9391 82.1546L61.5305 74.4935C61.9949 74.3691 62.4723 74.6447 62.5967 75.1091L70.2578 103.701C70.3822 104.165 70.1066 104.642 69.6422 104.767L41.0508 112.428C40.5863 112.552 40.109 112.277 39.9845 111.812L32.3235 83.2208Z'
						fill='#EFEDEE'
					/>
					<path
						d='M38.2497 21.2457C38.1253 20.7813 38.4009 20.3039 38.8653 20.1795L40.5472 19.7288C41.0116 19.6044 41.489 19.88 41.6134 20.3444L42.0641 22.0263C42.1885 22.4907 41.9129 22.9681 41.4485 23.0925L39.7666 23.5432C39.3022 23.6676 38.8248 23.392 38.7004 22.9276L38.2497 21.2457Z'
						fill='#EFEDEE'
					/>
					<path
						d='M42.4543 20.1192C42.3299 19.6548 42.6055 19.1774 43.0699 19.053L44.7518 18.6023C45.2162 18.4779 45.6936 18.7535 45.818 19.2179L46.2687 20.8998C46.3931 21.3642 46.1175 21.8416 45.6531 21.966L43.9712 22.4167C43.5068 22.5411 43.0294 22.2655 42.905 21.8011L42.4543 20.1192Z'
						fill='#EFEDEE'
					/>
					<path
						d='M25.982 32.6445C26.3553 34.0377 25.5285 35.4699 24.1352 35.8432C22.7419 36.2165 21.3098 35.3897 20.9365 33.9964C20.5631 32.6031 21.39 31.171 22.7833 30.7977C24.1766 30.4243 25.6087 31.2512 25.982 32.6445Z'
						fill='#EFEDEE'
					/>
					<path
						d='M32.7093 30.8422C33.0826 32.2355 32.2558 33.6676 30.8625 34.041C29.4692 34.4143 28.0371 33.5874 27.6638 32.1942C27.2904 30.8009 28.1173 29.3687 29.5106 28.9954C30.9039 28.6221 32.336 29.4489 32.7093 30.8422Z'
						fill='#EFEDEE'
					/>
					<path
						d='M39.4368 29.0395C39.8102 30.4328 38.9833 31.8649 37.5901 32.2382C36.1968 32.6116 34.7646 31.7847 34.3913 30.3914C34.018 28.9981 34.8448 27.566 36.2381 27.1927C37.6314 26.8193 39.0635 27.6462 39.4368 29.0395Z'
						fill='#EFEDEE'
					/>
					<path
						d='M46.1641 27.2367C46.5375 28.63 45.7106 30.0622 44.3173 30.4355C42.9241 30.8088 41.4919 29.982 41.1186 28.5887C40.7453 27.1954 41.5721 25.7633 42.9654 25.3899C44.3587 25.0166 45.7908 25.8435 46.1641 27.2367Z'
						fill='#EFEDEE'
					/>
					<path
						d='M52.8914 25.434C53.2648 26.8273 52.4379 28.2594 51.0446 28.6328C49.6514 29.0061 48.2192 28.1792 47.8459 26.786C47.4726 25.3927 48.2994 23.9605 49.6927 23.5872C51.086 23.2139 52.5181 24.0407 52.8914 25.434Z'
						fill='#EFEDEE'
					/>
				</g>
			</g>
			<path
				d='M27.0319 19.7453C26.7831 18.8165 27.3343 17.8617 28.2631 17.6128L33.3087 16.2609C34.2375 16.012 35.1923 16.5632 35.4412 17.4921C35.6901 18.4209 35.1388 19.3757 34.21 19.6246L29.1644 20.9765C28.2356 21.2254 27.2808 20.6742 27.0319 19.7453Z'
				fill='#FF510E'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M26.7934 72.6732L20.5825 49.4937C21.6656 49.1724 22.8127 49 24 49C30.6275 49 36 54.3726 36 61C36 66.6657 32.0736 71.4143 26.7934 72.6732Z'
				fill='black'
			/>
			<rect x='11' y='46' width='24' height='24' rx='12' fill='#FF510E' />
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M23 63.0909C25.8116 63.0909 28.0909 60.8116 28.0909 58C28.0909 55.1884 25.8116 52.9091 23 52.9091C20.1884 52.9091 17.9091 55.1884 17.9091 58C17.9091 60.8116 20.1884 63.0909 23 63.0909ZM23 66C27.4183 66 31 62.4183 31 58C31 53.5817 27.4183 50 23 50C18.5817 50 15 53.5817 15 58C15 62.4183 18.5817 66 23 66Z'
				fill='white'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M18.9377 64.1192L16.8806 62.0622L27.0624 51.8804L29.1195 53.9374L18.9377 64.1192Z'
				fill='white'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M53.0776 29.4917L53.8836 32.4999H47.4271L44.3417 38.6707L41.6584 37.329L45.1584 30.329L45.573 29.4999H46.5001H53.0735L53.0776 29.4917Z'
				fill='black'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M50.3759 29.5L51.1798 32.5H47.4271L44.3417 38.6708L41.6584 37.3292L45.1584 30.3292L45.573 29.5H46.5001H50.3759Z'
				fill='black'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M60.0001 17H68.5001V26H65.5001V22.1213L63.6214 24H56.9271L53.4271 31H45.9267L42.8412 37.1708L40.158 35.8292L44.0725 28H51.573L55.073 21H62.3788L63.3788 20H60.0001V17Z'
				fill='#B5DEF4'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M62.4942 64.6351L63.8259 69.6052L58.4223 65.1768L52.1605 67.4191L51.2877 67.7316L50.6178 67.0907L44.9629 61.6804L47.0368 59.5127L52.0219 64.2821L58.2105 62.0661L59.0101 61.7798L59.667 62.3182L62.4942 64.6351Z'
				fill='black'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M59.0293 61.7955L60.3433 66.6994C60.349 66.7206 60.3538 66.7418 60.3578 66.763L58.4223 65.1768L52.1605 67.4191L51.2877 67.7316L50.6178 67.0907L44.9629 61.6804L47.0368 59.5127L52.0219 64.2822L58.2105 62.0661L59.0101 61.7798L59.0293 61.7955Z'
				fill='black'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M70.832 70.2483L77.8998 65.5366L74.3618 57.8717L71.638 59.129L73.2571 62.6368L70.8472 61.9332L65.7098 64.2706L59.0102 58.7802L52.0219 61.2825L47.0368 56.513L44.9629 58.6807L51.2877 64.7319L58.4224 62.1772L65.2526 67.7746L71.0757 65.1252L72.4894 65.5379L69.1679 67.7521L70.832 70.2483Z'
				fill='#B5DEF4'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M57.1427 44.6636L57.9568 47.7017L56.7866 48.4466L56.4821 48.6404L56.1228 48.6745L47.1417 49.527L46.8582 46.5404L55.48 45.722L57.1427 44.6636Z'
				fill='black'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M55.5285 48.7312L54.741 45.7925L46.8582 46.5408L47.1417 49.5273L55.5285 48.7312Z'
				fill='black'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M74.528 46.2395L81.7038 39.4855L74.5136 32.8943L72.4864 35.1057L75.8095 38.152L70.6622 38.3658L65.589 43.6549L59.3703 40.5751L54.4801 43.6882L45.8582 44.5066L46.1417 47.4932L55.4822 46.6065L59.5545 44.0141L66.2303 47.3203L71.9921 41.3132L75.5415 41.1657L72.4719 44.055L74.528 46.2395Z'
				fill='#B5DEF4'
			/>
			<defs>
				<clipPath id='clip0_15054_5493'>
					<rect
						x='15.1987'
						y='19.3105'
						width='31.3412'
						height='68.7765'
						rx='1.74118'
						transform='rotate(-15 15.1987 19.3105)'
						fill='white'
					/>
				</clipPath>
				<clipPath id='clip1_15054_5493'>
					<rect width='31.3412' height='68.7765' fill='white' transform='translate(15.1987 19.3105) rotate(-15)' />
				</clipPath>
				<clipPath id='clip2_15054_5493'>
					<rect
						x='15.1987'
						y='19.3105'
						width='31.3412'
						height='68.7765'
						rx='1.74118'
						transform='rotate(-15 15.1987 19.3105)'
						fill='white'
					/>
				</clipPath>
				<clipPath id='clip3_15054_5493'>
					<rect width='31.3412' height='68.7765' fill='white' transform='translate(15.1987 19.3105) rotate(-15)' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default BlueLight;
