import { memo, useState } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { BF_MY_TOPS_COPY, BUILD_FLOW_STEPS, MEDIUM_WIDTH } from '@constants';
import { useIsMobile } from '@utils/hooks';
import { useBFContext } from '@context';
import { Bag, Caption, Close, Drawer, Flex, Heading, LabelText, Modal, MyTops } from '@components';
import styles from './ProductViewAllTops.module.scss';

const TopFrameEducation = dynamic(() => import('@components').then(mod => mod.TopFrameEducation));
const CartActions = dynamic(() => import('@components').then(mod => mod.CartActions));
const StepTracker = dynamic(() => import('@components').then(mod => mod.StepTracker));

const ProductViewAllTops = () => {
	const { tops, step, handleTop } = useBFContext();
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const hasTops = Object.keys(tops).length !== 0;
	const [modalOpen, setModalOpen] = useState(false);

	function handleModalChange(shouldOpen: boolean) {
		setModalOpen(shouldOpen);
	}

	const isTopFrameStep = step === BUILD_FLOW_STEPS.TOP_FRAMES || step === BUILD_FLOW_STEPS.SUBMIT;
	const titleProductView = 'Choose Top Frames';

	const triggerButton = (
		<Flex className={styles['shopping-bag-container']}>
			<Flex
				className={cn(styles['shopping-bag-trigger'], {
					[styles['is-active']]: hasTops,
				})}
				align='center'
				justify='center'
			>
				<Bag />
			</Flex>
			<Flex
				align='center'
				justify='center'
				className={cn(styles['mobile-count'], {
					[styles['is-active']]: hasTops,
				})}
			>
				<Caption style={{ fontWeight: 700, fontSize: '1.4rem' }}>{Object.keys(tops).length}</Caption>
			</Flex>
		</Flex>
	);

	const topFrameContent = (
		<>
			<Flex gap={3} align='center' justify='start' className={styles.header} fullWidth>
				<Flex gap={3} align='center'>
					<Caption>{hasTops ? 'My Top Frames' : BF_MY_TOPS_COPY}</Caption>
					{hasTops && (
						<Flex
							align='center'
							justify='center'
							className={cn(styles['tops-counter'], { [styles['is-active']]: hasTops })}
						>
							<LabelText style={{ fontWeight: 700, fontSize: '1.4rem' }}>{Object.keys(tops).length}</LabelText>
						</Flex>
					)}
				</Flex>
				{!isMobile && (
					<Drawer.Close asChild>
						<Close wrapperClass={styles['close-button']} height={13} width={13} label={'Close'} />
					</Drawer.Close>
				)}
			</Flex>
			<Flex column align='center' fullWidth className={styles.drawerContent}>
				<MyTops tops={tops} onTrashClick={handleTop} />
			</Flex>
		</>
	);

	return (
		<>
			<Flex
				column
				align='center'
				justify='between'
				className={styles['product-view']}
				data-use-padding={!isTopFrameStep}
				data-use-height-tops-step={isTopFrameStep}
			>
				{isMobile ? (
					<Flex column fullWidth align='center' className={styles['no-preview']}>
						<Flex fullWidth justify='center' className={styles['preview-header']}>
							<Heading tag='h6' style={{ marginTop: '0' }}>
								{titleProductView}
							</Heading>
							<Modal open={modalOpen} onOpenChange={handleModalChange}>
								<Modal.Trigger>{triggerButton}</Modal.Trigger>
								<Modal.Content className={styles.modalContent}>{topFrameContent}</Modal.Content>
							</Modal>
						</Flex>
						<TopFrameEducation isMobile />
					</Flex>
				) : (
					<Flex justify='between' fullWidth align='center'>
						<Flex column>
							<Heading tag='h6' style={{ marginTop: '0' }}>
								{titleProductView}
							</Heading>
							<TopFrameEducation isMobile />
						</Flex>
						<Flex>
							<StepTracker />
						</Flex>
						<Flex>
							<Drawer>
								<Drawer.Trigger asChild>{triggerButton}</Drawer.Trigger>
								<Drawer.Content blurOverlay={false} grayBackground>
									<div>
										{topFrameContent}
										<div className={styles['bf-drawer-cart-actions']}>
											<CartActions />
										</div>
									</div>
								</Drawer.Content>
							</Drawer>
						</Flex>
					</Flex>
				)}
			</Flex>
		</>
	);
};

export default memo(ProductViewAllTops);
