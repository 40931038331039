import { useRef } from 'react';
import framePlaceholder from '@public/frame-placeholder.svg';
import { BuildFlowCard, Img, ProductGrid } from '@components';
import { NormalizedVariant } from '@ts/product';
import { useOnScreen } from '@utils/hooks';
import styles from './CollectionProductGrid.module.scss';

type BuildFlowCardProps = {
	top: NormalizedVariant;
	collectionHandle: string;
};

const ProductItem = (props: BuildFlowCardProps) => {
	// Adds lazy display to improve performance
	const cardRef = useRef(null);
	const { ref, isOnScreen } = useOnScreen();

	if (!props.top.availableForSale) return null;

	return (
		<div ref={ref}>
			{isOnScreen ? (
				<BuildFlowCard ref={cardRef} collectionHandle={props.collectionHandle} {...props} />
			) : (
				<div
					className={styles['card-placeholder']}
					style={{
						height: cardRef?.current?.clientHeight || 'auto',
					}}
				>
					<Img src={framePlaceholder.src} className={styles['image-placeholder']} noSrcset width={250} height={125} />
					<div className={styles['content-placeholder']} />
				</div>
			)}
		</div>
	);
};

function CollectionProductGrid({ collection, useLazyLoad = false, className = null }) {
	const mappedCardList = collection.products.map((top: NormalizedVariant) =>
		useLazyLoad ? (
			<ProductItem key={`top-card-${top.handle}`} top={top} collectionHandle={collection.handle} />
		) : (
			<BuildFlowCard key={`top-card-${top.handle}`} top={top} collectionHandle={collection.handle} />
		)
	);

	return (
		<ProductGrid className={className} title={collection.title} type='build-flow'>
			{mappedCardList}
		</ProductGrid>
	);
}

export default CollectionProductGrid;
