import { MutableRefObject, Fragment, useCallback, useEffect, useRef, useState, createRef } from 'react';
import { m } from 'framer-motion';
import { TypographyButton } from '@components';
import variables from '@styles/export.module.scss';
import styles from './ScrollNav.module.scss';

type ScrollNavProps = {
	items: {
		title: string;
		ref: MutableRefObject<HTMLElement>;
	}[];
};

const headerHeight = parseFloat(variables.headerHeight) * 10;
const scrollNavHeight = parseFloat(variables.scrollNavHeight) * 10;
const containerMargin = 32;

const ScrollNav = ({ items }: ScrollNavProps) => {
	const [selectedItem, setSelectedItem] = useState(0);
	const [ticking, setTicking] = useState(false);
	const containerRef = useRef(null);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const navItemRefs = items.map(i => createRef<HTMLDivElement>());

	const containerOffest =
		headerHeight +
		scrollNavHeight +
		containerMargin +
		(containerRef.current &&
			containerRef.current.getBoundingClientRect().top + containerRef.current.getBoundingClientRect().height);

	function isElementInViewport(element) {
		const rect = element.getBoundingClientRect();

		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
		);
	}

	const updateScrollNav = useCallback(() => {
		if (!ticking) {
			window.requestAnimationFrame(() => {
				for (let i = 0; i < items.length; i++) {
					const header = items[i].ref.current.querySelector('div[class^="AccessoryGrid_heading"]');

					if (isElementInViewport(header)) {
						setSelectedItem(i);
						break;
					}
				}
				setTicking(false);
			});
			setTicking(true);
		}
	}, [containerOffest, items, ticking]);

	const scrollToTab = useCallback(() => {
		const isAndroid = /Android/.test(window.navigator.userAgent);
		navItemRefs[selectedItem]?.current?.scrollIntoView({ block: 'nearest', behavior: isAndroid ? 'auto' : 'smooth' });
	}, [selectedItem, navItemRefs]);

	useEffect(() => {
		window.addEventListener('scroll', updateScrollNav);
		return () => {
			window.removeEventListener('scroll', updateScrollNav);
		};
	}, [updateScrollNav]);

	useEffect(() => {
		if (!ticking) scrollToTab();
	}, [selectedItem, navItemRefs, ticking, scrollToTab]);

	const scrollToItem = (item: ScrollNavProps['items'][number]) => {
		item.ref.current.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<div data-testid='scroll-nav' className={styles.container} ref={containerRef}>
			{items.map((item, i) => {
				return (
					<Fragment key={`scrollnav-${item.title}`}>
						<div
							className={i === selectedItem ? styles.selected : styles.item}
							data-type={item.title}
							onClick={() => {
								scrollToItem(item);
								setSelectedItem(i);
								setTicking(true);
								setTimeout(() => {
									setTicking(false);
								}, 500);
							}}
							ref={navItemRefs[i]}
						>
							<TypographyButton small>{item.title}</TypographyButton>
							{i === selectedItem && <m.div className={styles.underline}></m.div>}
						</div>
					</Fragment>
				);
			})}
		</div>
	);
};
export default ScrollNav;
