import { Fragment, memo, useMemo } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { Text } from '@contentful/rich-text-types';
import { AccordianCell, Button, Checkbox, ControlledMeasurementInput, Flex, Input, Paragraph } from '@components';
import { RXSS_MEASURE_PD_BUTTON_COPY, RxFieldOption } from '@utils/constants/rxss';
import { useContentfulAsset } from '@services/contentful/hooks';
import { RxssPomsPayload } from '@ts/rxss';
import { VisualAssetEntrySkeletonType, VisualAssetFields } from '@ts/contentful';
import { useRXSSContext } from '@context';
import styles from './PDMeasurementCell.module.scss';

type PDMeasurementCellProps = {
	control: Control<FieldValues, RxssPomsPayload>;
	id: string;
	measurements: RxFieldOption['measurements'];
	pdToolHasError: boolean;
	pdValue?: number;
	handleToggleTwoPds: () => void;
	handleUpdateActiveInput: (id: string) => void;
	setPdModalOpen: (x: boolean) => void;
};

const PDMeasurementCell = ({
	control,
	handleToggleTwoPds,
	handleUpdateActiveInput,
	id,
	measurements,
	pdToolHasError,
	setPdModalOpen,
	pdValue,
}: PDMeasurementCellProps) => {
	const ContentfulRequest = useContentfulAsset<VisualAssetEntrySkeletonType>({
		id,
		query: {
			'content_type': 'visualAsset',
			'fields.slug[in]': measurements.map(m => m.cmsSlug).join(','),
		},
	});
	const { hasTwoPds, isPdFieldSelected } = useRXSSContext();

	const actionableInputsMemo = useMemo(
		() =>
			isPdFieldSelected ? (
				<Checkbox
					key='two-pds-checkbox'
					option='two-pds'
					checked={hasTwoPds}
					handler={() => handleToggleTwoPds()}
					className={styles.checkboxContainer}
				>
					<Flex fullWidth justify='between' align='center'>
						<Paragraph>I have 2 PD values</Paragraph>
					</Flex>
				</Checkbox>
			) : (
				<>
					{!pdToolHasError && !pdValue && (
						<Button
							fullWidth
							label={RXSS_MEASURE_PD_BUTTON_COPY}
							color='white'
							size='medium'
							onClick={() => setPdModalOpen(true)}
						/>
					)}
				</>
			),

		[handleToggleTwoPds, hasTwoPds, isPdFieldSelected, pdToolHasError, pdValue, setPdModalOpen]
	);

	return (
		<Flex
			column
			fullWidth
			backgroundColor='white'
			borderRadius={3}
			pad={4}
			style={{ borderRadius: '1.6rem' }}
			data-field-option={id}
		>
			{measurements.map(({ inputs }, index) => {
				const fields = ContentfulRequest?.data?.[index]?.fields ?? ({} as VisualAssetFields);
				const { media, description, title, shortDescription } = fields;

				const showNotice = isPdFieldSelected || (!isPdFieldSelected && pdToolHasError);

				return (
					<Fragment key={`measurement-${index}`}>
						<AccordianCell
							key={title}
							title={title}
							notice={showNotice && shortDescription}
							imageSrc={media?.fields?.file?.url as string}
							hasImage={!!media?.fields?.file?.url}
							description={!!description?.content ? (description?.content?.[0]?.content?.[0] as Text)?.value : null}
							initiallyExpanded={!isPdFieldSelected}
						>
							{(isPdFieldSelected || pdToolHasError) &&
								inputs.map(({ id: inputId, label, values }, index) => {
									if (hasTwoPds) {
										if (index === 2) return;
									} else {
										if (index !== 2) return;
									}

									return (
										<ControlledMeasurementInput
											key={inputId}
											control={control}
											handleUpdateActiveInput={handleUpdateActiveInput}
											label={label}
											id={inputId}
											values={values}
											centerInstruction
										/>
									);
								})}
							{pdValue && !isPdFieldSelected && !pdToolHasError && (
								<Input
									disabled
									readOnly
									name='single_pd'
									id='single_pd'
									value={pdValue}
									placeholder='Pupillary Distance'
									required
								/>
							)}
						</AccordianCell>
						{actionableInputsMemo}
					</Fragment>
				);
			})}
		</Flex>
	);
};

export default memo(PDMeasurementCell);
